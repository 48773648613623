/** @jsx jsx */
import { jsx } from "theme-ui";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/Layout";
import Divider from "../components/Divider";

const About = () => {
  return (
    <Layout
      styling={"layout.secondary"}
      additionalStyles={{ maxWidth: "40rem", textAlign: "center" }}
    >
      <h1>Chase Fenske</h1>
      <Divider />
      <div
        sx={{
          display: "grid",
          px: ["6rem", "10rem", "12rem", "12rem"],
          py: "2rem",
        }}
      >
        <div
          sx={{
            gridColumn: 1,
            gridRow: 1,
            backgroundColor: "secondary",
            borderRadius: "10px",
            width: ["100%", "100%", "99.9%", "99.9%"],
          }}
        />
        <div sx={{ gridColumn: 1, gridRow: 1 }}>
          <StaticImage
            sx={{ variant: "images" }}
            src="../images/profile.png"
            alt="Profile"
          />
        </div>
      </div>
      <p>
        Hello there! I'm Chase, a software engineer living and working in Los
        Angeles, CA.
      </p>
      <p>
        I graduated from UNC - Chapel Hill with a degree in Biology, and
        Computer Science minor.
      </p>
      <p>
        Even from my handful of CS classes, it was clear to me that I wanted to
        program.
      </p>
      <br />
      <p>
        While studying for the AWS Solutions Architect Associate exam I
        developed a keen interest in serverless architecture.
      </p>
      <p>
        I primarily work in javascript, writing serverless applications with
        Nodejs, and React applications with typescript.
      </p>
      <p>
        Recently I have been re-exploring my biology background by playing
        around with metaheuristic bio-algorithms.
      </p>
    </Layout>
  );
};

export default About;

/** @jsx jsx */
import { jsx } from "theme-ui";
import { alpha } from "@theme-ui/color";

const Divider = () => {
  return (
    <div
      sx={{
        height: ".15rem",
        mx: "-10rem",
        backgroundImage: (t) => `
          radial-gradient(
            circle,
            ${alpha("text", 0.8)(t)},
            ${alpha("background", 0.8)(t)},
            ${t.colors.background}
          )
        `,
      }}
    />
  );
};
// ${alpha("text", 0.8)(t)},
// ${alpha("background", 0.8)(t)},
// ${t.colors.background}

export default Divider;
